import appGraphql from 'api/app_graphql'

import {
  DELETE_DRAFT,
  DRAFT_RECIPIENT_SYNC_STARTED,
  DRAFT_RECIPIENT_SYNC_FINISHED,
} from 'ducks/drafts2/constants'
import {
  selectDraftById,
  selectDraftDefaultByTicketId,
  selectDraftIdByTicketId,
} from 'ducks/drafts2/selectors'

import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { NEW_CONVERSATION_ID, SNOOZED } from 'ducks/tickets/constants'

import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentAgentsById } from 'selectors/agents/base'
import { selectFolderIdsMatchingDraftForCurrentUser } from 'ducks/folders/selectors/folders/selectFolderIdsMatchingDraftForCurrentUser'

import { doClearUserSuggestion } from 'actions/tickets/doClearUserSuggestion'
import { doSearchUsers } from 'actions/tickets/doSearchUsers'

import windowUnload from 'util/windowUnload'
import { isEmpty } from 'util/objects'
import debug from 'util/debug'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { selectCurrentTeamsById } from 'ducks/teams/selectors'
import { doReply } from 'ducks/tickets/actions/doReply'
import { isTwitter } from 'ducks/tickets/utils/type'
import { emailFromAuthor } from 'util/author'
import { doAddNote } from 'ducks/tickets/actions/doAddNote'
import { doCreateEmail } from 'ducks/tickets/actions/doCreateEmail'
import { doLog } from 'ducks/tickets/actions/doLog'
import { doForward } from 'ducks/tickets/actions/doForward'
import { hash } from 'util/scatterSwap'
import { doFetchTagsV2ByIds } from 'ducks/tags/actions'

import { doUpsertReplyDraft } from './doUpsertReplyDraft'
import { doSendDraftToServer } from './doSendDraftToServer'

const MIN_RECIPIENT_QUERY_LENGTH = 1
const updateDebounces = {}
const postUpdateCallbacks = {}
const sendDebounces = {}

// set cc and bcc to default values
export function doSwitchToReplyAll(ticketId, draftType, draftId) {
  return (dispatch, getState) => {
    const state = getState()
    const { to = [], cc = [], bcc = [] } = selectDraftById(state, draftId) || {}
    const messageId = null
    const ticket = selectCurrentConversationById(state, ticketId)
    const draftDefaults = selectDraftDefaultByTicketId(
      state,
      ticketId,
      draftType
    )

    if (!ticket || isEmpty(ticket)) {
      debug('ticket not found')
      return 2
    }

    const fields = {
      replyType: 'reply-all',
      isForwarding: undefined,
      to: to && (to?.length || 0) > 0 ? [to[0]] : draftDefaults.to,
      cc: cc && (cc?.length || 0) > 0 ? cc : draftDefaults.cc,
      bcc: bcc && (bcc?.length || 0) > 0 ? bcc : draftDefaults.bcc,
    }
    handleDraftChange(dispatch, draftType, draftId, ticketId, messageId, fields)

    return 0
  }
}

// Remove all cc and bcc's
export function doSwitchToDirectReply(ticketId, draftType, draftId) {
  return (dispatch, getState) => {
    const state = getState()
    const { to = [] } = selectDraftById(state, draftId) || {}
    const messageId = null
    const ticket = selectCurrentConversationById(state, ticketId)
    const draftDefaults = selectDraftDefaultByTicketId(
      state,
      ticketId,
      draftType
    )

    if (!ticket || isEmpty(ticket)) {
      debug('ticket not found')
      return 2
    }

    const fields = {
      replyType: 'reply-direct',
      isForwarding: undefined,
      to: to && (to?.length || 0) > 0 ? [to[0]] : draftDefaults.to,
      cc: null,
      bcc: null,
    }
    handleDraftChange(dispatch, draftType, draftId, ticketId, messageId, fields)

    return 0
  }
}

export const doDeleteDraftLocally = (ticketId, draftId) => (
  dispatch,
  getState
) => {
  if (updateDebounces[draftId]) clearTimeout(updateDebounces[draftId])
  if (sendDebounces[draftId]) clearTimeout(draftId[draftId])
  // eslint-disable-next-line import/no-named-as-default-member
  windowUnload.uninstall()

  const state = getState()
  const draftFolderIds = selectFolderIdsMatchingDraftForCurrentUser(state)
  const currentUserId = selectCurrentUserId(state)

  return dispatch({
    type: DELETE_DRAFT,
    payload: { draftId, ticketId },
    meta: {
      currentUserId,
      draftFolderIds,
    },
  })
}

export function doDeleteDraft(ticketId, draftId) {
  return dispatch => {
    dispatch(doDeleteDraftLocally(ticketId, draftId))
    dispatch(doDeleteDraftOnServer(ticketId, draftId))
  }
}

function doDeleteDraftOnServer(ticketId, draftId) {
  return (dispatch, getState) => {
    const state = getState()
    const mutation = `
      mutation DraftDelete(
        $draftId: ID!,
      ) {
        draftDelete( input: {
          draftId: $draftId,
        }) {
          success
        }
      }
    `

    const variables = {
      draftId,
    }

    const token = oauthTokenSelector(state)
    return appGraphql(token, mutation, variables)
  }
}

const sendDraftTimeout = 500
export function debouncedSendDraftToServer(
  dispatch,
  draftId,
  ticketId,
  draftType
) {
  if (sendDebounces[draftId]) clearTimeout(sendDebounces[draftId])
  sendDebounces[draftId] = setTimeout(() => {
    dispatch(doSendDraftToServer(draftId, ticketId, draftType))
    delete sendDebounces[draftId]
  }, sendDraftTimeout)
}

export const doChangeDraft = (
  draftId,
  draftType,
  ticketId,
  messageId,
  fields
) => (dispatch, getState) => {
  // eslint-disable-next-line import/no-named-as-default-member
  windowUnload.install(
    'Your draft is not yet saved, closing this window may result in data loss'
  )
  let ticketDraftId = draftId

  if (!ticketDraftId) {
    // make doubly sure that ticket draftId has not been updated since this call
    // because the debouncedHandleDraftChange#updateDebounces could have been stored with no draftId value (new draft)
    // and at the time debouncedHandleDraftChange executes doChangeDraft, draft could have been created (say, by inserting a canned reply)
    ticketDraftId = selectDraftIdByTicketId(getState(), ticketId, draftType)
  }

  dispatch(
    doUpsertReplyDraft(ticketDraftId, draftType, ticketId, messageId, fields)
  )
  debouncedSendDraftToServer(dispatch, ticketDraftId, ticketId, draftType)
}

export function handleDraftChange(
  dispatch,
  draftType,
  draftId,
  ticketId,
  messageId,
  fields
) {
  return dispatch(
    doChangeDraft(draftId, draftType, ticketId, messageId, fields)
  )
}

export function debouncedHandleDraftChange(
  dispatch,
  draftType,
  draftId,
  ticketId,
  messageId,
  fields,
  oldBody
) {
  // eslint-disable-next-line import/no-named-as-default-member
  windowUnload.install(
    'Your draft is not yet saved, closing this window may result in data loss'
  )
  const key = draftId

  if (updateDebounces[key]) {
    clearTimeout(updateDebounces[key].timeout)
    updateDebounces[key].fields = { ...updateDebounces[key].fields, ...fields }
  } else {
    updateDebounces[key] = { fields }
  }

  updateDebounces[key].timeout = setTimeout(() => {
    const accumulatedFields = updateDebounces[key].fields
    delete updateDebounces[key]

    const hasOnlyBodyField =
      'body' in accumulatedFields && Object.keys(accumulatedFields).length === 1
    const isChanged =
      ![null, undefined].includes(oldBody) && hasOnlyBodyField
        ? oldBody !== accumulatedFields.body
        : true

    if (isChanged) {
      handleDraftChange(
        dispatch,
        draftType,
        draftId,
        ticketId,
        messageId,
        accumulatedFields
      )
      if (postUpdateCallbacks[key]) {
        postUpdateCallbacks[key].forEach(callback => callback())
        delete postUpdateCallbacks[key]
      }
    }
  }, 500)
}

const waitForDraftUpdate = async draftId => {
  if (updateDebounces[draftId]) {
    return new Promise(resolve => {
      postUpdateCallbacks[draftId] = [
        () => {
          resolve(null)
        },
      ]
    })
  }
  return null
}

function addSecondsToCurrentTimestamp(seconds) {
  const parsedSeconds = parseInt(seconds, 10) // Parse the input to an integer
  if (isNaN(parsedSeconds)) {
    throw new Error('Invalid input: seconds must be a valid number')
  }
  const currentDate = new Date() // Get the current date and time
  currentDate.setSeconds(currentDate.getSeconds() + parsedSeconds) // Add the parsed seconds
  return currentDate
}

export const applyAutomaticActionsToDraft = (variables, automaticActions) => {
  const newVariables = Object.assign({}, variables)

  automaticActions.forEach(action => {
    const { type, value } = action

    switch (type) {
      case 'status': {
        const newState = value.toUpperCase()
        newVariables.state = newState
        if (newState !== SNOOZED) newVariables.snoozeUntil = null
        break
      }
      case 'labels_by_id': {
        const tagsToAdd = []
        const values = (value || '').split(',')

        values.forEach(tagId => {
          if (!['', null].includes(tagId)) tagsToAdd.push(tagId)
        })

        newVariables.tagsToAddById = tagsToAdd
        break
      }
      case 'label_remove': {
        if (value !== null) newVariables.tagsToRemoveById = [value]

        break
      }
      case 'label_remove_all':
        newVariables.removeAllLabels = true
        break
      case 'assignee_id':
        newVariables.assigneeId = hash(value)
        newVariables.assigneeGroupId = null
        break
      case 'assignee_group_id':
        newVariables.assigneeGroupId = hash(value)
        newVariables.assigneeId = null
        break
      case 'snooze_until':
        newVariables.snoozeUntil = value
        // Value van also be null to indicate snoozed indefinitely
        if (value) {
          newVariables.snoozeUntil = addSecondsToCurrentTimestamp(value)
        }
        newVariables.state = SNOOZED
        delete newVariables.removeSnooze
        break
      default:
    }
  })

  return newVariables
}

export const doSendReplyDraft = (draftId, options = {}) => async (
  dispatch,
  getState
) => {
  await waitForDraftUpdate(draftId)

  const store = getState()
  const draft = selectDraftById(store, draftId)
  if (!draft) return null

  const {
    ticketId,
    to,
    cc,
    bcc,
    title: subject,
    attachments = {},
    templateId: cannedReplyId,
    snoozeUntil: draftSnoozeUntil,
    assigneeId: draftAssigneeId,
    assigneeGroupId: draftAssigneeGroupId,
    isNote,
    phoneNumber,
    twitterPrefix,
    mailboxId,
    isForwarding,
  } = draft
  let { body, state: draftState } = draft
  const isNewTicket = ticketId === NEW_CONVERSATION_ID
  const ticket = selectCurrentConversationById(store, ticketId)
  const agentsById = selectCurrentAgentsById(store)
  const groupsById = selectCurrentTeamsById(store)

  // Prevent old drafts with the lower case status format from breaking
  if (draftState) draftState = draftState.toUpperCase()
  if (!draftState) draftState = ticket.state

  if (isTwitter(ticket)) {
    body = `${twitterPrefix} ${body}`
  }
  // Kevin R
  // This method used to to be call inside the doSendChangeset method which was essentially
  // just before the request is sent off to the server. This seems ludicrous to me because
  // the whole fucking point about the "draft" system is to know what'll happen when you hit
  // send. We need to circle back here and move these "automatic" actions to be applied when
  // the canned reply is added to the conversation. That would then also allow customers to
  // modify the actions if they want to and see what'll happen when they hit send. Yet another
  // poor implementation.
  const automatedChangedVariables = applyAutomaticActionsToDraft(
    {
      state: draftState,
      snoozeUntil: draftSnoozeUntil,
      assigneeId: draftAssigneeId,
      assigneeGroupId: draftAssigneeGroupId,
    },
    draft.automaticActions || []
  )
  const {
    state,
    tagsToAddById: tagIdsToAdd,
    tagsToRemoveById: tagIdsToRemove,
    removeAllLabels: removeAllTags,
    snoozeUntil,
  } = automatedChangedVariables
  let { assigneeId, assigneeGroupId } = automatedChangedVariables

  const requiredLoadedTags = [...(tagIdsToAdd || []), ...(tagIdsToRemove || [])]
  if (requiredLoadedTags.length > 0) {
    await dispatch(
      doFetchTagsV2ByIds({
        ids: requiredLoadedTags,
      })
    )
  }

  if (assigneeId === null || (assigneeId && !agentsById[assigneeId]))
    assigneeId = null
  if (
    assigneeGroupId === null ||
    (assigneeGroupId && !groupsById[assigneeGroupId])
  )
    assigneeGroupId = null

  if (isNewTicket && isNote) {
    return dispatch(
      doLog(
        body,
        (to || []).map(emailFromAuthor),
        subject,
        Object.values(attachments),
        cannedReplyId,
        draftId,
        state,
        snoozeUntil,
        assigneeId,
        assigneeGroupId,
        mailboxId,
        phoneNumber,
        options
      )
    )
  } else if (isNewTicket) {
    return dispatch(
      doCreateEmail(
        body,
        to.map(emailFromAuthor),
        cc?.map(emailFromAuthor),
        bcc?.map(emailFromAuthor),
        subject,
        Object.values(attachments),
        cannedReplyId,
        draftId,
        state,
        snoozeUntil,
        tagIdsToAdd,
        assigneeId,
        assigneeGroupId,
        mailboxId,
        options
      )
    )
  } else if (isNote) {
    return dispatch(
      doAddNote(
        ticketId,
        body,
        draftId,
        state,
        snoozeUntil,
        assigneeId,
        assigneeGroupId,
        Object.values(attachments),
        options
      )
    )
  } else if (isForwarding) {
    return dispatch(
      doForward(
        ticketId,
        body,
        to.map(emailFromAuthor),
        cc?.map(emailFromAuthor),
        bcc?.map(emailFromAuthor),
        subject,
        Object.values(attachments),
        cannedReplyId,
        draftId,
        state,
        snoozeUntil,
        tagIdsToAdd,
        tagIdsToRemove,
        removeAllTags,
        assigneeId,
        assigneeGroupId,
        mailboxId,
        options
      )
    )
  }
  return dispatch(
    doReply(
      ticketId,
      body,
      to.map(emailFromAuthor),
      cc?.map(emailFromAuthor),
      bcc?.map(emailFromAuthor),
      subject,
      Object.values(attachments),
      cannedReplyId,
      draftId,
      state,
      snoozeUntil,
      tagIdsToAdd,
      tagIdsToRemove,
      removeAllTags,
      assigneeId,
      assigneeGroupId,
      mailboxId,
      options
    )
  )
}

export function doSearchRecipients(term) {
  return dispatch => {
    dispatch(doClearUserSuggestion())

    if (!term || term.length < MIN_RECIPIENT_QUERY_LENGTH) {
      return Promise.resolve()
    }

    return dispatch(doSearchUsers(term))
  }
}

export function doStartRecipientSyncSearch(source) {
  return {
    type: DRAFT_RECIPIENT_SYNC_STARTED,
    payload: {
      source,
    },
  }
}

export function doFinishRecipientSyncSearch() {
  return {
    type: DRAFT_RECIPIENT_SYNC_FINISHED,
    payload: {},
  }
}

export function doSyncDraftWithSearchRecipients(
  draftType,
  ticketId,
  searchRecipients = []
) {
  return (dispatch, getState) => {
    const state = getState()
    const draftId = selectDraftIdByTicketId(state, ticketId, draftType)
    const draft = selectDraftById(state, draftId)
    const updates = {}
    const searchRecipientsByEmail = searchRecipients.reduce(
      (result, recipient) => {
        if (recipient.email) {
          // eslint-disable-next-line no-param-reassign
          result[recipient.email] = recipient
        }
        return result
      },
      {}
    )
    ;['to', 'cc', 'bcc'].forEach(recipientType => {
      const draftRecipients = draft[recipientType] || []
      const syncedRecipients = []
      let changed = false
      draftRecipients.forEach(draftRecipient => {
        const searchRecipient = searchRecipientsByEmail[draftRecipient.email]
        if (searchRecipient && searchRecipient.email === draftRecipient.email) {
          syncedRecipients.push({
            ...searchRecipient,
            name: draftRecipient.name || searchRecipient.name,
          })
          changed = true
        } else {
          syncedRecipients.push(draftRecipient)
        }
      })
      if (changed) {
        updates[recipientType] = syncedRecipients
      }
    })
    if (Object.keys(updates).length > 0) {
      dispatch(doChangeDraft(draftId, draftType, ticketId, null, updates))
    }
  }
}
